import useScreen from "hooks/useScreen";
import "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiClock, FiPause, FiPlay } from "react-icons/fi";
import { IoReloadOutline } from "react-icons/io5";

import moment from "moment-timezone";
import { useEffect, useState } from "react";
import classNames from "utilities/ClassNames";
import { checkSaudiDayNight } from "./utils/checkSaudiDayNight";
import { formatter, excludeStartDate, excludeEndDate } from "./config/constants";
import { timeZoneTime } from "./utils/timezoneTime";
import useApp from "hooks/useApp";
import { cn } from "@/lib/utils";

export default function DateTime() {
  const {isScaled } = useScreen();
  const {user}= useApp();
  const [day, setDaySkyBox] = useState(1);
  const defaultTimeZone = "Asia/Kuwait";
  const userTimeZone = user?.ianaTimeZone || defaultTimeZone;
  const [timer, runTimer] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    timeZoneTime(userTimeZone, formatter)
  );
  const [calendar, showCalendar] = useState(false);
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (calendar) {
      runTimer(false);
    } else {
      runTimer(true);
      if (!isCurrentDate) {
        setPaused(false);
      }
    }
  }, [calendar, isCurrentDate]);

  useEffect(() => {
    checkSaudiDayNight(
      selectedDate,
      userTimeZone,
      formatter,
      setDaySkyBox,
      day
    );
  }, [selectedDate, day, userTimeZone]);

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      setSelectedDate((state) => {
        let newDate;
        if (isCurrentDate) {
          newDate = timeZoneTime(userTimeZone, formatter);
        } else {
          newDate = state;
          const x = newDate.getSeconds() + 1;
          newDate.setSeconds(x);
        }

        return new Date(newDate);
      });
    }, 1000);

    if (!timer || paused) {
      clearInterval(secondsTimer);
    }
    return () => {
      clearInterval(secondsTimer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line
  }, [timer, paused, isCurrentDate, userTimeZone]);

  const clockIconHandler = () => {
    showCalendar((state) => !state);
  };

  const calendarContainer = ({ children }: any) => {
    return (
      <div className="calendar">
        <div className="sidebar-card">
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const handleCalendarClose = () => {};

  const handleCalendarOpen = () => {
    showCalendar(true);
  };

  const dateTimeHandler = (date: Date) => {
    setSelectedDate(date);
    setIsCurrentDate(false);
  };

  const playPauseHandler = () => {
    setPaused((state) => !state);
  };

  const refreshHandler = () => {
    setSelectedDate(timeZoneTime(userTimeZone, formatter));
    setIsCurrentDate(true);
    setPaused(false);
    runTimer(true);
  };

  const disablePauseBtn = paused ? "play-pause-disable" : "";
  const disablePlayBtn = !paused ? "play-pause-disable" : "";

  return (
    <div
      className="date-time-container date-time flex justify-center items-center mr-7"
      style={{ position: "relative" }}
    >
      {calendar && (
        <div
          style={{
            position: "fixed",
            zIndex: "10",
            inset: 0,
            width: "100%",
            height: "100%",
          }}
          onClick={() => showCalendar(false)}
        />
      )}
      <div
        className="card-content flex items-center"
        style={{ position: "relative", zIndex: "20" }}
      >
        <div className="mr-3">
          {!isCurrentDate && (
            <div
              className="hover:text-green cursor-pointer"
              onClick={refreshHandler}
            >
              <IoReloadOutline size={isScaled ? 25 : 22} />
            </div>
          )}
        </div>

        <div className="mr-1">
          <div
            className={`${
              isCurrentDate
                ? "text-lightGray cursor-default"
                : paused
                ? "text-green cursor-pointer"
                : "text-lightGray cursor-default"
            }`}
          >
            <div onClick={playPauseHandler}>
              <FiPlay size={22} className={cn("play-pause-btn lg:w-[80%] 2xl:w-full")} />
            </div>
          </div>
        </div>
        <div className="mr-3">
          <div
            className={`${
              isCurrentDate
                ? "text-lightGray cursor-default"
                : !paused
                ? "text-green cursor-pointer"
                : "text-lightGray cursor-default"
            }`}
          >
            <div onClick={playPauseHandler}>
              <FiPause size={22} className={cn(paused?"play-pause-btn-active":"","play-pause-btn lg:w-[80%] 2xl:w-full")} />
            </div>
          </div>
        </div>

        <DatePicker
          selected={selectedDate}
          onInputClick={handleCalendarOpen}
          onChange={dateTimeHandler}
          showTimeSelect={true}
          timeIntervals={30}
          className={classNames(
            "lg:w-[200px] lg:text-sm 2xl:w-[225px] 2xl:text-base mr-3",
            " border-none outline-none bg-dark cursor-pointer"
          )}
          dateFormat="MMMM d, yyyy h:mm:ss aa"
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={timeZoneTime(userTimeZone, formatter)}
          calendarContainer={calendarContainer}
          startOpen={false}
          timeInputLabel="Time:"
          includeDateIntervals={[
            {
              start: new Date("May 5, 2021"),
              end: timeZoneTime(userTimeZone, formatter),
            },
          ]}
          excludeDateIntervals={[
            { start: excludeStartDate, end: excludeEndDate },
          ]}
          open={calendar}
          showDisabledMonthNavigation
        />

        <div className="hover:text-green cursor-pointer">
          <FiClock size={22} className="lg:w-[75%] xl:w-[80%] 2xl:w-full" onClick={clockIconHandler} />
        </div>
      </div>
    </div>
  );
}
